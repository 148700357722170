<template>
	<div>
		<header class="a-header isStatic">
			<div class="app-container app-container-fluid a-header__container">
				<div class="row align-items-center">
					<div class="col-auto">
						<div class="a-header__logo" >
							<router-link
								:to="$i18nRoute({ name: 'index' })"
								class="a-header__logo--link"
								v-html="require('!html-loader!@/assets/logo-color.svg')"
							/>
						</div>
					</div>
				</div>
			</div>
		</header>
		<div class='a-comparison'>
			<div class="app-container a-comparison-container">
				<div class="a-comparison-header">
					<div class="row align-items-center">
						<div class="col">
							<p class='a-heading-1' v-if='comparison.count'>{{$t('account.comparison.title.transfers')}} {{comparison.count ? `(${comparison.count} ${$t('account.with')} ${comparison.maxCount})` : ""}}</p>
						</div>
					</div>
				</div>

				<template v-if='items.length > 0'>
					<div v-for='(item, i) in items' :key='i'>
						<trips-card
							:item='item'
							:onlyRead='true'
						/>
					</div>
				</template>

				<template v-else>
					<trips-preloader
						name='transfers'
						:title='$t("account.search.preloader.transfers.no_found")'
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import TripsPreloader from '@/views/account/search/components/preloader';
	import TripsCard from '@/views/account/search/transfers/card';
	import { getShareComparison } from '@/services/request';

	export default {
		data: () => ({
			comparison: {}
		}),
		components: {
			TripsPreloader,
			TripsCard
		},
		computed: {
			items() {
				return this.comparison.items ? Object.values(this.comparison.items) : [];
			}
		},
		created() {
			this.getShareComparison();
		},
		methods: {
			getShareComparison() {
				getShareComparison(this.$route.params.comparisonId).then(res => this.comparison = res.data ?? {});
			}
		}
	}
</script>
